.globalClass .ant-alert-message {
  margin-bottom: 0;
}

.globalClass .ant-form-item-label {
  padding: 0;
}

.globalClass .ant-form-item {
  margin-bottom: 16px;
}

.globalClass .ant-table {
  border-radius: 0;
}

.globalClass .ant-table-cell {
  background: #fff !important;
}

.globalClass .ant-table-wrapper .ant-pagination {
  border: 1px solid #f0f0f0;
  border-top: none;
  background-color: #fff;
  margin: 0;
  padding: 16px 0;
}

.globalClass .ant-tabs-nav {
  margin-bottom: 0;
}

.globalClass .ant-tabs-tabpane > div {
  padding: 0;
}

.globalClass .ant-btn-text {
  color: #547756;
}
