/* @import url('https://fonts.googleapis.com/css2?family=Cairo:wght@500&family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap'); */
@font-face {
  font-family: "SF Pro Display";
  src: url(./font/FontsFree-Net-SFProDisplay-Regular.ttf);
}

@font-face {
  font-family: "Proxima Nova";
  src: url(./font/Proxima\ Nova\ Font.otf);
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-family: "Montserrat", sans-serif; */
}

li {
  list-style: none;
}

pre {
  font-family: "SF Pro Display";
}

a {
  color: inherit;
  text-decoration: inherit;
  font-family: inherit;
  font-size: inherit;
}

/* Common css */
.page-title {
  font-family: "SF Pro Display";
  font-weight: 700;
  font-size: 34px;
  line-height: 40px;
  letter-spacing: 0.02em;
  color: #3d3b48;
}

.info-box {
  background: #e9e5e1;
  padding: 12px;
  border-radius: 10px;
  font-size: 12px;
  margin-bottom: 30px;
}

.info-content {
  min-height: 50px;
  font-size: 14px;
  font-weight: bold;
  word-wrap: break-word;
}

/* Tool css */
.full-width {
  width: 100%;
}
.full-height {
  height: 100%;
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.right {
  float: right;
}
.c-primary {
  color: #547756;
}
.c-gray {
  color: #999;
}
.c-white {
  color: #fff;
}
.fs-12 {
  font-size: 12px;
}
.fs-13 {
  font-size: 13px;
}
.fs-14 {
  font-size: 14px;
}
.inline-block {
  display: inline-block;
}
.vertical-middle {
  vertical-align: middle;
}
.flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.flex-start {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.flex-around {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.flex {
  flex: 1;
}
.text-cut {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.mt-16 {
  margin-top: 16px;
}
.mt-10 {
  margin-top: 10px;
}
.mb-10 {
  margin-bottom: 10px;
}
.ml-10 {
  margin-left: 10px;
}
.mr-10 {
  margin-right: 10px;
}
.bold {
  font-weight: bold;
}
.gray-all {
  color: #aaa !important;
  opacity: 0.75;
}
.gray-all * {
  color: #aaa !important;
  opacity: 0.75;
}
